import {setPageData, getRiskCategories} from '@js/landing/redux/actions';
import {AppState} from '@js/landing/redux/rootReducer';
import {selectBuyNowUrl, selectGlobalRiskType, selectIsDataFetched} from '@js/landing/redux/selectors';
import {connect} from 'react-redux';
import {LandingComponentWithRouter} from './landing.component';

const mapStateToProps = (state: AppState) => ({
  globalRiskType: selectGlobalRiskType(state),
  isDataFetched: selectIsDataFetched(state),
  buynowUrl: selectBuyNowUrl(state),
});

const mapDispatchToProps = {
  setPageData,
  getRiskCategories,
};

export const LandingContainer = connect(mapStateToProps, mapDispatchToProps)(LandingComponentWithRouter);
