import {connect} from 'react-redux';
import {StepsToProtectComponent} from './steps-to-protect.component';
import {AppState} from '@js/landing/redux/rootReducer';
import {selectBuyNowUrl} from '@js/landing/redux/selectors';

const mapStateToProps = (state: AppState) => ({
  buyNowUrl: selectBuyNowUrl(state),
});
const mapDispatchToProps = {};

export const StepsToProtectContainer = connect(mapStateToProps, mapDispatchToProps)(StepsToProtectComponent);
