import {RiskTypes, SET_PAGE_DATA} from '../constants';
import {PageData, TSetPageDataAction} from '../types';

const initialState: PageData = {
  isLoading: false,
  isDataFetched: false,
  availableCategoryTypes: [],
  isShowErrorPopup: false,
  isShowPaywallPopup: false,
  globalRiskType: RiskTypes.unknown,
  buyNowUrl: '/buynow',
};

type TActionsType = TSetPageDataAction;

export const pageDataReducer = (state = initialState, action: TActionsType): PageData => {
  switch (action.type) {
    case SET_PAGE_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
