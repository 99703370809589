type Pages =
  | '/alp-init'
  | '/alp-scan-completed'
  | '/alp-buynow'
  | '/alp-checkout'
  | '/alp-signup-success'
  | '/alp-thank-you-page';

export const sendPageEventGTM = (page: Pages, eventName: string) => {
  // if (FL_LANDING_ENV !== 'Prod') {
  //   return;
  // }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'gtm.newHistoryState': page, event: eventName});
};
