import React, {memo} from 'react';
import {ButtonComponent} from '@js/pure-components/button/button.component';
import {FigleafGradientLogoComponent} from '@js/pure-components/fl-gradient-logo/fl-gradient-logo.component';
import {sendAnalytics} from '@js/analytics/sendAnalytics';
import IconCheckmarkCurrentColor from '@common/images/icon-checkmark-current-color.svg';
import IconTimerSmall from '@common/images/icon-timer.svg';
import IconClose from '@common/images/icon-close.svg';

import {PaywallPopupProps} from './paywall-popup.types';
import './_paywall-popup.scss';

const features = [
  'See sensitive exposed data',
  'Monitoring and alerting of any breach of your account information',
  'Prevent accounts exposure',
  'Eliminate location exposure',
];

export const PaywallPopupComponent = memo<PaywallPopupProps>(({setPageData, isShowPaywallPopup, buynowUrl}) => {
  if (!isShowPaywallPopup) {
    return null;
  }

  const openBuynow = () => {
    sendAnalytics({
      eventName: 'click_on_buy_now_button',
      payload: {
        location: 'paywall_popup',
      },
    });
    window.location.href = buynowUrl;
  };

  return (
    <div className="paywall-popup__overlay">
      <div className="paywall-popup__body">
        <IconClose className="paywall-popup__close" onClick={() => setPageData({isShowPaywallPopup: false})} />
        <FigleafGradientLogoComponent />
        <h1 className="paywall-popup__title">Get FigLeaf Premium</h1>
        <h1 className="paywall-popup__subtitle">Access the Full Report & Eliminate Privacy Risks</h1>
        <div className="paywall-popup__separator" />

        <h4 className="paywall-popup__features-title">Activate today and:</h4>
        <div className="paywall-popup__features">
          {features.map((item, index) => (
            <div className="paywall-popup__feature" key={index}>
              <IconCheckmarkCurrentColor className="paywall-popup__feature-icon" />
              {item}
            </div>
          ))}
        </div>

        <div className="paywall-popup__buttons">
          <ButtonComponent text="Activate with up to 86% discount" sizeModifier="sm" onClick={openBuynow} fullWidth />
          <button type="button" onClick={openBuynow} className="download-section__download-button">
            <div className="download-section__button-icon">
              <IconTimerSmall />
            </div>
            Limited-time offer
          </button>
        </div>
      </div>
    </div>
  );
});

PaywallPopupComponent.displayName = 'PaywallPopupComponent';
