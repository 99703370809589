import {RiskTypes} from '@js/landing/redux/constants';
import React from 'react';

import RiskScaleHigh from './images/risk-scale-high.png';
import RiskScaleHigh2x from './images/risk-scale-high2x.png';
import RiskScaleMedium from './images/risk-scale-medium.png';
import RiskScaleMedium2x from './images/risk-scale-medium2x.png';
import RiskScaleLow from './images/risk-scale-low.png';
import RiskScaleLow2x from './images/risk-scale-low2x.png';
import RiskScaleUnknown from './images/risk-scale-unknown.png';
import RiskScaleUnknown2x from './images/risk-scale-unknown2x.png';

import {Props} from './risk-rank.types';
import './_risk-rank.scss';

export const RiskRankComponent: React.FC<Props> = ({
  amountOfExposedCategories,
  totalAmount,
  isDataFetched,
  riskType,
}) => {
  let riskScaleImage = null;
  let riskScaleText = '';
  switch (riskType) {
    case RiskTypes.high: {
      riskScaleImage = (
        <img
          src={RiskScaleHigh}
          alt="high risk"
          srcSet={`${RiskScaleHigh} 1x, ${RiskScaleHigh2x} 2x`}
          className="risk-rank__image"
        />
      );
      riskScaleText = 'High';
      break;
    }
    case RiskTypes.moderate: {
      riskScaleImage = (
        <img
          src={RiskScaleMedium}
          alt="moderate risk"
          srcSet={`${RiskScaleMedium} 1x, ${RiskScaleMedium2x} 2x`}
          className="risk-rank__image"
        />
      );
      riskScaleText = 'Moderate';
      break;
    }
    case RiskTypes.low: {
      riskScaleImage = (
        <img
          src={RiskScaleLow}
          alt="low risk"
          srcSet={`${RiskScaleLow} 1x, ${RiskScaleLow2x} 2x`}
          className="risk-rank__image"
        />
      );
      riskScaleText = 'Low';
      break;
    }
    default: {
      riskScaleImage = (
        <img
          src={RiskScaleUnknown}
          alt="unknown risk"
          srcSet={`${RiskScaleUnknown} 1x, ${RiskScaleUnknown2x} 2x`}
          className="risk-rank__image"
        />
      );
      riskScaleText = 'Unknown';
    }
  }

  return (
    <div className="risk-rank">
      {riskScaleImage}
      <span className="risk-rank__title">Data Exposure</span>
      <span className="risk-rank__status">{riskScaleText}</span>
      <span className="risk-rank__description">
        {isDataFetched ? (
          <>
            {amountOfExposedCategories} out of {totalAmount}
            <br /> risk categories
          </>
        ) : (
          <>
            Check your <br />
            email to start
          </>
        )}
      </span>
    </div>
  );
};
