export enum RiskTypes {
  unknown,
  low,
  moderate,
  high,
}

export const SET_PAGE_DATA: 'SET_PAGE_DATA' = 'SET_PAGE_DATA';

export const GET_RISK_CATEGORIES: 'GET_RISK_CATEGORIES' = 'GET_RISK_CATEGORIES';
export const SET_RISK_CATEGORIES: 'SET_RISK_CATEGORIES' = 'SET_RISK_CATEGORIES';
