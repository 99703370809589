import {TRiskCategoriesState, TSetRiskCategoriesAction} from '@js/landing/redux/types';
import {RiskTypes, SET_RISK_CATEGORIES} from '@js/landing/redux/constants';

export const riskCategoriesInitialState: TRiskCategoriesState = {
  discoveredRecords: {
    riskType: RiskTypes.unknown,
    items: {
      generalInfo: {
        name: 'General Info',
        riskBadge: 'Exposed',
        data: [],
      },
      ids: {
        name: 'IDs',
        riskBadge: 'Exposed',
        subItems: {
          idsSsn: {
            name: 'SSN',
            data: [],
          },
          idsDriversLicence: {
            name: 'Driver’s license',
            data: [],
          },
          idsPassport: {
            name: 'Passport',
            data: [],
          },
          idsTaxId: {
            name: 'Tax ID',
            data: [],
          },
        },
      },
      phones: {
        name: 'Phones',
        riskBadge: 'Exposed',
        data: [],
      },
      socialProfiles: {
        name: 'Social profiles',
        riskBadge: 'Publicy Available',
        data: [],
      },
    },
  },
  breachedAccounts: {
    riskType: RiskTypes.unknown,
    items: {
      accounts: {
        name: 'Accounts',
        riskBadge: 'Exposed',
        data: [],
      },
      logins: {
        name: 'Logins',
        riskBadge: 'Exposed',
        data: [],
      },
      passwords: {
        name: 'Passwords',
        riskBadge: 'Exposed',
        data: [],
      },
      securityQuestions: {
        name: 'Security questions',
        data: [],
        riskBadge: 'Exposed',
      },
    },
  },
  exposedLocations: {
    riskType: RiskTypes.unknown,
    items: {
      locations: {
        name: 'Locations',
        riskBadge: 'Exposed',
        data: [],
      },
      ipAddresses: {
        name: 'IP addresses',
        riskBadge: 'Exposed',
        data: [],
      },
    },
  },
};

export const riskCategoriesReducer = (
  state: TRiskCategoriesState = riskCategoriesInitialState,
  action: TSetRiskCategoriesAction,
): TRiskCategoriesState => {
  switch (action.type) {
    case SET_RISK_CATEGORIES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
