import sanitize from 'sanitize-filename';
import {ulid} from 'ulid';

const LANDING_ID = '1.0';

export function initAnalytics(): void {
  /**
   * Init session time
   */
  const referrer = document.referrer;
  if (!referrer.includes(window.location.host) || !localStorage.getItem('activeSession')) {
    localStorage.setItem('activeSession', new Date().toString());
  }

  const getParams = new URLSearchParams(window.location.search);
  const campaignIdFromGetParams = sanitize(getParams.get('campaign_id') || '');
  if (campaignIdFromGetParams) {
    if (localStorage.getItem('campaign_id') !== campaignIdFromGetParams) {
      localStorage.setItem('campaign_id', campaignIdFromGetParams);
    }
  } else if (!localStorage.getItem('campaign_id')) {
    localStorage.setItem('campaign_id', '');
  }

  if (!localStorage.getItem('landing_id') || localStorage.getItem('landing_id') !== LANDING_ID) {
    localStorage.setItem('landing_id', LANDING_ID);
  }

  if (!localStorage.getItem('user_id')) {
    localStorage.setItem('user_id', ulid());
  }
}
