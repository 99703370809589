import React from 'react';
import {CategoryContainer} from '@js/landing/components/category/category.container';
import {BreachIsThreatContainer} from '../breach-is-threat/breach-is-threat.container';
import {StepsToProtectContainer} from '../steps-to-protect/steps-to-protect.container';
import {RiskTypes} from '@js/landing/redux/constants';

import {CategoriesSectionProps} from './categories-section.types';
import './_categories-section.scss';

export const CategoriesSectionComponent: React.FC<CategoriesSectionProps> = ({
  availableCategories,
  isDataFetched,
  isLoading,
  email,
  globalRiskType,
}) => {
  const riskCategories = availableCategories.map((categoryName, index, arr) => {
    return (
      <CategoryContainer
        categoryName={categoryName}
        key={categoryName}
        partNumber={index + 1}
        partsCount={arr.length}
      />
    );
  });

  let bottomBlock = null;

  if (!isLoading && !isDataFetched) {
    bottomBlock = (
      <div className="categories-section__bottom-block">
        <BreachIsThreatContainer />
      </div>
    );
  }

  if (globalRiskType === RiskTypes.high || globalRiskType === RiskTypes.moderate) {
    bottomBlock = (
      <div className="categories-section__bottom-block">
        <StepsToProtectContainer />
      </div>
    );
  }

  const isStatusDefined = !isLoading && isDataFetched;

  return (
    <section className="categories-section">
      <div className="categories-section__inner">
        <div className="categories-section__header">
          {isStatusDefined ? (
            <>
              <h2 className="categories-section__title">Exposed Data Report for “{email}”</h2>
              <p className="categories-section__description">
                Using FigLeaf gives you everything you need to control your digital footprint.
              </p>
              <h6 className="categories-section__subtitle">FigLeaf empowers you with:</h6>
              <ul className="categories-section__list">
                <li className="categories-section__list-item"> • Power to stop website tracking</li>
                <li className="categories-section__list-item"> • Easy management of your account information</li>
                <li className="categories-section__list-item">
                  • Knowledge of when a new breach occurs and how it impacts you
                </li>
              </ul>
            </>
          ) : (
            <>
              <h2 className="categories-section__title">Exposed Data Risk Report</h2>
              <h6 className="categories-section__subtitle">Did you know?</h6>
              <ul className="categories-section__list">
                <li className="categories-section__list-item">
                  • Most websites collect your browsing behavior and personal information
                </li>
                <li className="categories-section__list-item">
                  • Over 6 billion personal records have been leaked or hacked since 2008
                </li>
                <li className="categories-section__list-item">
                  • Data brokers collect consumer data and sell it to target you with advertising and content
                </li>
              </ul>
              <p className="categories-section__description">
                Running a periodic privacy scan empowers you with knowledge so you can control your digital footprint.
              </p>
            </>
          )}
        </div>
        <div className="categories-section__content">
          {riskCategories}
          {bottomBlock}
        </div>
      </div>
    </section>
  );
};
