import {ulid} from 'ulid';
import {UAParser} from 'ua-parser-js';
import {handleActiveTime} from './handleActiveTime';
import {getReferrer} from './getReferrer';

type eventType = {
  event_id: string;
  event_name: string;
  event_context: string;
  event_source: string;
  event_time_diff: number;
  payload: string;
  platform: string | undefined;
  os_version: string | null;
};

export function sendAnalytics({
  eventName = '',
  eventSource,
  payload = {},
}: {
  eventName: string;
  eventSource?: 'page' | 'user';
  payload: any;
}) {
  return new Promise(resolve => {
    const eventContext = FL_LANDING_EVENT_CONTEXT || 'armani_landing';

    /**
     * Converts os name to figleaf event platform parameter
     * @return {string}
     */
    function convertOSEventName(os: string = '') {
      const osMap = new Map([['Mac OS', 'macOS']]);
      if (!osMap.has(os)) {
        return os;
      }
      return osMap.get(os);
    }

    const parser = new UAParser();

    const osName = convertOSEventName(parser.getOS().name);
    const osVersion = parser.getOS().version || 'unknown';
    const browser = parser.getBrowser().name;

    handleActiveTime(payload);
    payload.browser = browser;
    payload.referrer = getReferrer(payload);
    payload.language = window.navigator.language;
    payload.campaign_id = localStorage.getItem('campaign_id') || 'unknown';
    payload.landing_id = localStorage.getItem('landing_id') || 'unknown';
    payload.user_id = localStorage.getItem('user_id') || 'unknown';

    const event: eventType = {
      event_id: ulid(),
      event_name: eventName,
      event_source: eventSource || '',
      event_context: eventContext,
      event_time_diff: 0,
      platform: osName,
      os_version: osVersion,
      payload: JSON.stringify(payload),
    };

    const analyticsURL = FL_LANDING_ANALYTICS_URL || '';

    if (!analyticsURL) {
      console.table(event);
      console.log("event analytics url is empty. probably it's local env.");
      return resolve(null);
    }

    const batch = {
      events: [event],
    };

    return window
      .fetch(analyticsURL, {
        method: 'POST',
        body: JSON.stringify(batch),
        keepalive: true,
      })
      .then(() => {
        resolve();
      })
      .catch(err => console.error(err));
  });
}
