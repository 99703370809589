import {connect} from 'react-redux';
import {
  selectIsDataFetched,
  selectRiskCategories,
  selectIsLoading,
  selectBuyNowUrl,
  selectGlobalRiskType,
} from '@js/landing/redux/selectors';
import {AppState} from '@js/landing/redux/rootReducer';
import {CategoryComponent} from './category.component';

const mapStateToProps = (state: AppState) => ({
  riskCategories: selectRiskCategories(state),
  isDataFetched: selectIsDataFetched(state),
  isLoading: selectIsLoading(state),
  buyNowUrl: selectBuyNowUrl(state),
  globalRiskType: selectGlobalRiskType(state),
});

const mapDispatchToProps = {};

export const CategoryContainer = connect(mapStateToProps, mapDispatchToProps)(CategoryComponent);
