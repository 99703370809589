import React, {FC} from 'react';
import IconFigLeafAuraCurrentColor from '@common/images/icon-fl-aura-logo-current-color.svg';
import IconAuraCurrentColor from '@common/images/icon-aura-logo-current-color.svg';

import './_footer.scss';

export const FooterComponent: FC = () => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__top">
          <IconFigLeafAuraCurrentColor />
          <div className="footer__aura-logo">
            <IconAuraCurrentColor />
          </div>
        </div>
        <p className="footer__text">
          *This privacy checkup is provided free-of-charge. Other features may require a paid subscription.
        </p>
        <p className="footer__text">
          FigLeaf stands for privacy — for everyone. Everywhere. And because pretty much everyone lives some of their
          life online, we all deserve the right to choose privacy on our own terms. FigLeaf makes it possible.
        </p>
        <p className="footer__copy">© 2020 Aura Company All Rights Reserved</p>
      </div>
    </footer>
  );
};
