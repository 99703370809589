import React, {FC, useEffect} from 'react';
import './_landing.scss';
import sanitize from 'sanitize-filename';
import {HeaderContainer} from '../header/header.container';
import {withRouter} from 'react-router-dom';
import {CategoriesSectionContainer} from '../categories-section/categories-section.container';
import {PageData, TRiskCategoryType} from '../../redux/types';
import {BuyNowUrlMapIndex, PageProps} from './landing.types';
import {emailRegex} from '@js/utils/helpers';
import {FooterComponent} from '@js/pure-components/footer/footer.component';
import {ScanEmailAgainContainer} from '@js/landing/components/scan-email-again/scan-email-again.container';
import {ErrorPopupContainer} from '../error-popup/error-popup.container';
import {initAnalytics} from '@js/analytics/initAnalytics';
import {sendAnalytics} from '@js/analytics/sendAnalytics';
import {
  SESSION_DISABLED_CATEGORIES,
  SESSION_ORDERED_CATEGORIES,
  SESSION_BUYNOW,
  buyNowUrlsMap,
  defaultRiskCategoryTypes,
} from '@js/utils/constants';
import {ScrollToTopComponent} from '@js/pure-components/scroll-to-top/scroll-to-top.component';
import {PaywallPopupContainer} from '../paywall-popup/paywall-popup.container';
import {DownloadSectionComponent} from '../download-section/download-section.component';
import {sendPageEventGTM} from '@js/analytics/eventsGTM';

const LandingComponent: FC<PageProps> = ({
  setPageData,
  getRiskCategories,
  isDataFetched,
  location,
  history,
  globalRiskType,
  buynowUrl,
}) => {
  useEffect(() => {
    sendPageEventGTM('/alp-init', 'landing-page-load');

    initAnalytics();

    const getParams = new URLSearchParams(location.search);
    const emailFromGetParam = getParams.get('mail') ? sanitize(getParams.get('mail') as string) : '';

    const buyNowFromGetParam = (getParams.get('buynow')
      ? sanitize(getParams.get('buynow') as string)
      : '') as BuyNowUrlMapIndex;
    const orderedCategoriesFromGetParam = getParams.get('order')
      ? sanitize(getParams.get('order') as string)
          .split(',')
          .filter(item => defaultRiskCategoryTypes.indexOf(item as TRiskCategoryType) > -1) // filter only valid values
      : false;
    const disabledCategoriesFromGetParam = getParams.get('disable')
      ? sanitize(getParams.get('disable') as string).split(',')
      : false;

    let predefinedBuyNow = '';

    if (buyNowFromGetParam && buyNowUrlsMap[buyNowFromGetParam]) {
      window.sessionStorage.setItem(SESSION_BUYNOW, buyNowUrlsMap[buyNowFromGetParam]);
      predefinedBuyNow = buyNowUrlsMap[buyNowFromGetParam];
    } else {
      const storedBuyNow = window.sessionStorage.getItem(SESSION_BUYNOW);
      if (storedBuyNow) {
        predefinedBuyNow = storedBuyNow;
      }
    }

    let orderedCategoryItems: Array<TRiskCategoryType> = [];
    if (orderedCategoriesFromGetParam) {
      window.sessionStorage.setItem(SESSION_ORDERED_CATEGORIES, JSON.stringify(orderedCategoriesFromGetParam));
      orderedCategoryItems = orderedCategoriesFromGetParam as Array<TRiskCategoryType>;
    } else {
      const storedOrderedCategoryItems = window.sessionStorage.getItem(SESSION_ORDERED_CATEGORIES);
      orderedCategoryItems = storedOrderedCategoryItems ? JSON.parse(storedOrderedCategoryItems) : [];
    }

    let disabledCategoryItems: Array<TRiskCategoryType> = [];
    if (disabledCategoriesFromGetParam) {
      window.sessionStorage.setItem(SESSION_DISABLED_CATEGORIES, JSON.stringify(disabledCategoriesFromGetParam));
      disabledCategoryItems = disabledCategoriesFromGetParam as Array<TRiskCategoryType>;
    } else {
      const storedDisabledCategoryItems = window.sessionStorage.getItem(SESSION_DISABLED_CATEGORIES);
      disabledCategoryItems = storedDisabledCategoryItems ? JSON.parse(storedDisabledCategoryItems) : [];
    }

    // exclude disabled and ordered category items
    const filteredCategoryItems = defaultRiskCategoryTypes.filter(
      item => disabledCategoryItems.indexOf(item) < 0 && orderedCategoryItems.indexOf(item) < 0,
    );

    // put ordererd category items on before the filtered category items
    const availableCategoryTypes = orderedCategoryItems.concat(filteredCategoryItems);

    const pageData: Partial<PageData> = {availableCategoryTypes};
    if (predefinedBuyNow) {
      pageData.buyNowUrl = predefinedBuyNow;
    }

    setPageData(pageData);
    sendAnalytics({
      eventName: 'page_load',
      payload: {
        load_type:
          orderedCategoryItems.length || disabledCategoryItems.length ? availableCategoryTypes.join(',') : 'default',
      },
    });

    if (emailRegex.test(emailFromGetParam)) {
      getRiskCategories({email: emailFromGetParam, source: 'automatically'});
    }
    history.replace('/');
  }, []);

  return (
    <>
      <HeaderContainer />
      {isDataFetched && <DownloadSectionComponent buyNowUrl={buynowUrl} riskType={globalRiskType} />}
      <CategoriesSectionContainer />
      {isDataFetched && <ScanEmailAgainContainer />}
      <FooterComponent isDataFetched={isDataFetched} />
      <ErrorPopupContainer />
      <PaywallPopupContainer />
      <ScrollToTopComponent />
    </>
  );
};

export const LandingComponentWithRouter = withRouter(LandingComponent);
