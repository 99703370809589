export const categoriesConfig = {
  breachedAccounts: {
    title: 'Breached Accounts',
    description:
      'Hackers who obtain the login credentials for one of your digital accounts may access that account or others.',
    descriptionHigh:
      'You have several vulnerable accounts. Hackers who obtain the login credentials for one of your digital accounts may access that account or others.',
    descriptionModerate:
      'Our scan found vulnerable accounts of yours as a result of data breaches. Hackers who obtain the login credentials for one of your digital accounts may access that account or others.',
    descriptionLow:
      'Fortunately, our scan did not find any exposed accounts associated with <<user email>>. However, this does not mean you are invulnerable. We recommend monitoring your accounts to empower you with the information you need to react when a breach does occur.',
    buttonProtectText: 'Check My Privacy',
    buttonProtectDescription: 'Find out what account data of yours has been exposed.',
    buttonKeepText: 'Activate with up to 86% discount',
    buttonDescription:
      'With FigLeaf, you can manage all your accounts’ security, set strong passwords, and get alerts for new breaches.',
  },
  discoveredRecords: {
    title: 'Discovered Records',
    description: `Data brokers profit when they collect data about you and combine that data with your browsing habits.`,
    descriptionHigh:
      'Our scan discovered these online records associated with your email address. 3rd parties use your Discovered Records, your email address, and website trackers to target you with ads.',
    descriptionModerate:
      'Our scan discovered these online records associated with your email address. 3rd parties use your Discovered Records, your email address, and website trackers to target you with ads.',
    descriptionLow:
      'Fortunately, our scan uncovered no records associated with this email address. But don’t be fooled into believing you are safe. It is a best practice to decouple your personal information from your email address and to block website trackers from profiling you.',
    buttonProtectText: 'Check My Privacy',
    buttonProtectDescription: 'Find out what info the Data Brokers have collected about you.',
    buttonKeepText: 'Activate with up to 86% discount',
    buttonDescription:
      'With FigLeaf, you can restrict ad targeting by masking your email address and blocking web trackers from profiling your online behavior.',
  },
  exposedLocations: {
    title: 'Exposed Locations',
    description:
      'Advertisers, websites and email providers collect your IP address to identify you, your physical location and demographics about you.',
    descriptionHigh:
      'Our scan has determined your IP address and known street addresses associated with your email. We recommend disguising your IP address by using a VPN (virtual private network) connection, every time you access the internet.',
    descriptionModerate:
      'Our scan has determined your IP address and known street addresses associated with your email. We recommend disguising your IP address by using a VPN (virtual private network) connection, every time you access the internet.',
    descriptionLow:
      'Fortunately, our scan did not uncover any location information associated with your email address. However, this does not mean that advertisers, websites and email providers will not collect your IP address to identify you, your physical location, and demographics about you. We recommend using a VPN (virtual private network) connection, every time you access the internet to prevent websites from associating your information and IP address.',
    buttonProtectText: 'Check My Privacy',
    buttonProtectDescription: 'See the location information that appears to websites.',
    buttonKeepText: 'Activate with up to 86% discount',
    buttonDescription:
      'With FigLeaf, you can protect your privacy by using our integrated VPN that prevents 3rd parties from knowing your physical location.',
  },
} as const;
