import {PlanTitle} from '@js/buynow/redux/types';
import {TRiskCategoryType} from '@js/landing/redux/types';
export const defaultRiskCategoryTypes: Array<TRiskCategoryType> = [
  'breachedAccounts',
  'discoveredRecords',
  'exposedLocations',
];

export const buyNowUrlsMap = {
  '1': 'https://google.com/?q=1', // TODO: add buynow urls
  '2': 'https://google.com/?q=2',
  '3': 'https://google.com/?q=3',
  '4': 'https://google.com/?q=4',
} as const;

export const SESSION_DISABLED_CATEGORIES = 'SESSION_DISABLED_CATEGORIES';
export const SESSION_ORDERED_CATEGORIES = 'SESSION_ORDERED_CATEGORIES';
export const SESSION_BUYNOW = 'SESSION_BUYNOW';

type PlanItem = {
  title: PlanTitle;
  offerCode: string;
  campaignCode: string;
  amountOfMonths: number;
  pricePerMonth: number;
  save: number;
  amountOfPeople: number;
  popular: boolean;
};

const armaniPlans: Array<PlanItem> =
  FL_LANDING_ENV === 'Prod'
    ? [
        {
          title: 'individual',
          offerCode: 'lwqj7tnbee',
          campaignCode: 'acr_landing',
          pricePerMonth: 4.99,
          save: 60,
          amountOfMonths: 12,
          amountOfPeople: 1,
          popular: false,
        },
        {
          title: 'family',
          offerCode: '3jq62wghgz',
          campaignCode: 'acr_landing',
          pricePerMonth: 7.99,
          save: 263.76,
          amountOfMonths: 12,
          amountOfPeople: 3,
          popular: true,
        },
        {
          title: 'family +',
          offerCode: '3yz9k9g23o',
          pricePerMonth: 9.99,
          save: 719.28,
          campaignCode: 'acr_landing',
          amountOfMonths: 12,
          amountOfPeople: 7,
          popular: false,
        },
      ]
    : [
        {
          title: 'individual',
          offerCode: 'xln87ffti4',
          campaignCode: 'acr_landing',
          pricePerMonth: 4.99,
          save: 60,
          amountOfMonths: 12,
          amountOfPeople: 1,
          popular: false,
        },
        {
          title: 'family',
          offerCode: 'wr608tcooc',
          campaignCode: 'acr_landing',
          pricePerMonth: 7.99,
          save: 263.76,
          amountOfMonths: 12,
          amountOfPeople: 3,
          popular: true,
        },
        {
          title: 'family +',
          offerCode: '1nyif1g6ys',
          pricePerMonth: 9.99,
          save: 719.28,
          campaignCode: 'acr_landing',
          amountOfMonths: 12,
          amountOfPeople: 7,
          popular: false,
        },
      ];

export const plansConfig: Array<PlanItem> = armaniPlans;

export const defaultPricePerMonth =
  15 || (plansConfig.find(plan => plan.title === 'individual') || plansConfig[0]).pricePerMonth;
