import React, {memo} from 'react';
import './_steps-to-protect.scss';
import {StepsToProtectProps} from './steps-to-protect.types';
import IconShieldInfo from '@common/images/icon-shield-info-current-color.svg';
import ImageTrackersShield from './images/trackers-shield.svg';
import {ButtonComponent} from '@js/pure-components/button/button.component';
import {sendAnalytics} from '@js/analytics/sendAnalytics';

export const StepsToProtectComponent = memo<StepsToProtectProps>(({buyNowUrl}) => {
  return (
    <section className="steps-to-protect">
      <div className="steps-to-protect__inner">
        <span className="steps-to-protect__top">
          <IconShieldInfo />
          Recommended action
        </span>

        <h2 className="steps-to-protect__title">3 easy steps to protect your privacy</h2>

        <div className="steps-to-protect__steps">
          <div className="steps-to-protect__step">
            <span className="steps-to-protect__step-number">1</span>
            <span className="steps-to-protect__step-description">
              Review your
              <br /> exposed data
            </span>
          </div>
          <div className="steps-to-protect__step">
            <span className="steps-to-protect__step-number">2</span>
            <span className="steps-to-protect__step-description">Choose your protection plan</span>
          </div>
          <div className="steps-to-protect__step">
            <span className="steps-to-protect__step-number">3</span>
            <span className="steps-to-protect__step-description">
              Activate your
              <br /> privacy protection
            </span>
          </div>
        </div>

        <div className="steps-to-protect__cta">
          <ButtonComponent
            text="Protect My Privacy"
            mode="green"
            fullWidth
            onClick={() => {
              sendAnalytics({
                eventName: 'click_on_buy_now_button',
                payload: {
                  location: 'stepsToProtect',
                },
              });
              window.location.href = buyNowUrl;
            }}
          />
        </div>
        <div className="steps-to-protect__image">
          <ImageTrackersShield />
        </div>
      </div>
    </section>
  );
});

StepsToProtectComponent.displayName = 'StepsToProtectComponent';
